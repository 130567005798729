
$kia-color: #06141f;

:root {
    --kia-bg: #05141f;
}

@font-face {
    font-family: 'kial';
    font-style: normal;
    src: url("/fonts/kia/DesignKL.eot");
    src: url("/fonts/kia/DesignKL.eot?#iefix") format("embedded-opentype"),
            url("/fonts/kia/DesignKL.woff2") format("woff2"),
            url("/fonts/kia/DesignKL.woff") format("woff"),
            url("/fonts/kia/DesignKL.ttf") format("truetype");
}

@font-face {
    font-family: 'kiam';
    font-style: normal;
    src: url("/fonts/kia/DesignKM.eot");
    src: url("/fonts/kia/DesignKM.eot?#iefix") format("embedded-opentype"),
            url("/fonts/kia/DesignKM.woff2") format("woff2"),
            url("/fonts/kia/DesignKM.woff") format("woff"),
            url("/fonts/kia/DesignKM.ttf") format("truetype");
}

@font-face {
    font-family: 'kiab';
    font-style: normal;
    src: url("/fonts/kia/DesignKB.eot");
    src: url("/fonts/kia/DesignKB.eot?#iefix") format("embedded-opentype"),
            url("/fonts/kia/DesignKB.woff2") format("woff2"),
            url("/fonts/kia/DesignKB.woff") format("woff"),
            url("/fonts/kia/DesignKB.ttf") format("truetype");
}

$kia-primary: #06141f;
$kia-secondary: #697279;

$kia-theme-colors: (
    "kia-primary":    $kia-primary,
    "kia-secondary":  $kia-secondary,
) !default;


@each $color, $value in $kia-theme-colors {
    .btn-#{$color} {
        @include button-variant($value, $value);
    }
}

@each $color, $value in $kia-theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }
}




#app.kia {
    font-family: "kiam", Tahoma, sans-serif;
}

#app.kia .navbar-brand {
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    margin: 0;
}

.logo {
    height: 40px;
}
